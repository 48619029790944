<template>
  <b-modal
    id="modalPermissionsUser"
    title="Gerenciar Permissões"
    title-class="font-18"
    hide-footer
    no-close-on-backdrop
    @hidden="$emit('closeModal', false)"
  >
    <form @submit.prevent="updatePermissions">
      <div class="form-row">
        <div class="col-sm-6">
          <div
            v-for="module in modules"
            :key="module.code"
            :class="{
              'pl-2': module.highLevel == 1,
              'pl-4': module.highLevel == 0,
            }"
          >
            <div
              class="form-check form-check-inline"
              :title="module.description"
            >
              <input
                type="checkbox"
                :id="module.code"
                class="form-check-input"
                v-model="modulesSelected"
                :value="module.code"
                @change="applyOnChildren($event)"
              />
              <label class="form-check-label" :for="module.code">
                {{ module.code }} - {{ module.name }}
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="modal-footer-custom">
        <button
          type="button"
          class="btn btn-secondary"
          title="Cancelar"
          @click="$emit('closeModal', false)"
        >
          Cancelar
        </button>
        <button
          type="submit"
          class="btn btn-primary"
          title="Salvar"
          :disabled="disableButton"
        >
          <i
            v-if="disableButton"
            class="spinner-grow spinner-grow-custom text-wither"
          />
          <span v-else>Salvar</span>
        </button>
      </div>
    </form>
  </b-modal>
</template>

<script>
export default {
  name: 'PermissionsUserModal',

  props: {
    userId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      modules: null,
      userModules: null,
      modulesSelected: null,
      disableButton: false,
    }
  },

  methods: {
    async getPermissions() {
      try {
        this.modules = await this.$store.dispatch('getModules')
        this.userModules = await this.$store.dispatch(
          'getUserModules',
          this.userId,
        )
        this.modulesSelected = this.userModules.map((element) => element.code)
        this.$bvModal.show('modalPermissionsUser')
      } catch (error) {
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
        this.$emit('closeModal', false)
      }
    },

    async updatePermissions() {
      this.disableButton = true
      try {
        const response = await this.$store.dispatch('updateUserModules', {
          userId: this.userId,
          modules: { modules: this.modulesSelected },
        })
        this.disableButton = false
        this.$root.$bvToast.toast(response.message, {
          title: 'Sucesso!',
          variant: 'success',
          solid: true,
        })
        this.$emit('closeModal', false)
      } catch (error) {
        this.disableButton = false
        if (error && error.message) {
          this.$root.$bvToast.toast(error.message, {
            title: 'Atenção!',
            variant: 'warning',
            solid: true,
          })
        } else {
          this.$root.$bvToast.toast(
            'Não foi possível se conectar ao servidor.',
            {
              title: 'Erro!',
              variant: 'danger',
              solid: true,
            },
          )
        }
      }
    },

    applyOnChildren(event) {
      if (event.target.value.length === 1) {
        const childrensCodes = this.modules
          .filter((element) => {
            return element.code.startsWith(`${event.target.value}.`)
          })
          .map((element) => element.code)

        if (childrensCodes.length > 0) {
          if (event.currentTarget.checked) {
            this.modulesSelected = [
              ...new Set([...this.modulesSelected, ...childrensCodes]),
            ]
          } else {
            this.modulesSelected = this.modulesSelected.filter(
              (element) => !childrensCodes.includes(element),
            )
          }
        }
      } else if (event.target.value.length === 4) {
        const childrensCodes = this.modules
          .filter((element) => {
            return element.code.startsWith(`${event.target.value}.`)
          })
          .map((element) => element.code)

        if (childrensCodes.length > 0) {
          if (event.currentTarget.checked) {
            this.modulesSelected = [
              ...new Set([...this.modulesSelected, ...childrensCodes]),
            ]
          } else {
            this.modulesSelected = this.modulesSelected.filter(
              (element) => !childrensCodes.includes(element),
            )
          }
        }
      }
    },
  },

  mounted() {
    this.getPermissions()
  },
}
</script>
